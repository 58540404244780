import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'

import { useIsMobile } from '../../hooks/useIsMobile'

type Props = {
  title: string
  description?: string
  children: React.ReactNode
}

export function OperationsCard({
  title,
  description,
  children,
}: Readonly<Props>): JSX.Element {
  const isMobile = useIsMobile()

  return (
    <Card
      sx={{
        maxWidth: '342px',
        width: '100%',
        maxHeight: '220px',
        borderRadius: '8px',
        p: 2,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CardContent
        sx={{
          p: 0,
          '&:last-child': {
            pb: 0,
          },
        }}
      >
        <Typography
          variant="subtitle1"
          sx={{ mb: '7px' }}
          data-intercom-target="PurchaseProduct"
        >
          {title}
        </Typography>

        {description ? (
          <Typography variant="caption" component="p" sx={{ mb: 2 }}>
            {description}
          </Typography>
        ) : null}

        {children}
      </CardContent>
    </Card>
  )
}
